import {
    getActivityLogRequest
} from '@/api/activity-log';

export const state = {
    list: [],
    loading: false,
    totalItems: 0,
    pagination: null,
}

export const mutations = {
    SET_LIST(state, list) {
        state.list = list;
    },
    SET_LOADING(state, value) {
        state.loading = value
    },
    SET_TOTAL_RECORDS(state, count) {
        state.totalItems = count;
    },
    SET_PAGINATION_SETTING (state, settings) {
        state.pagination = settings
    }
}

export const actions = {
    async getActivityLog({ commit }, params) {
        try {
            commit('SET_LIST', []);
            commit('SET_LOADING', true);
            const { data } = await getActivityLogRequest(params);
            if (data.data) {
                commit('SET_LIST', data.data);
                commit('SET_TOTAL_RECORDS', data.total || 0);
            }
        } catch (e) {
            console.error(e);
        } finally {
            commit('SET_LOADING', false);
        }
    },
};
