export default [
  {
    path: '/404',
    name: '404',
    component: require('./views/utility/404').default,
  },
  {
    path: '*',
    redirect: '404',
  },
  {
    path: '/',
    redirect: '/employees',
  },
  {
    path: '/settings/:index',
    name: 'settings',
    meta: { authRequired: true, title: 'Settings', roles: ['admin', 'hrm'] },
    component: () => import(/* webpackChunkName: "settings" */ './views/settings/index')
  },
  {
    path: '/employees',
    name: 'employees',
    meta: { authRequired: true, title: 'Employees', roles: ['admin', 'hrm'] },
    component: () => import(/* webpackChunkName: "employees" */ './views/employees/index')
  },
  {
    path: '/employees/:id',
    name: 'employee',
    meta: { authRequired: true, title: 'Employees', roles: ['admin', 'hrm'] },
    component: () => import(/* webpackChunkName: "employee" */ './views/employee/index')
  },
  {
    path: '/schedule/:id',
    name: 'schedule',
    meta: { authRequired: true, title: 'Individual schedule', roles: ['admin', 'hrm'] },
    component: () => import(/* webpackChunkName: "schedule" */ './views/schedule/index')
  },
  {
    path: '/interviews',
    name: 'interviews',
    meta: { authRequired: true, title: 'Interviews', roles: ['admin', 'hrm', 'recruiter'] },
    component: () => import(/* webpackChunkName: "interviews" */ './views/interviews/index')
  },
  {
    path: '/state',
    name: 'state',
    meta: { authRequired: true, title: 'Organization Structure', roles: ['admin'] },
    component: () => import(/* webpackChunkName: "state" */ './views/state/State')
  },
  {
    path: '/feedback',
    name: 'feedback',
    meta: { authRequired: true, title: 'Feedback', roles: ['admin', 'hrm'] },
    component: () => import(/* webpackChunkName: "feedback" */ './views/feedback/index')
  },
  {
    path: '/pages/starter',
    name: 'Starter',
    meta: { authRequired: true, roles: ['all'] },
    component: () => import(/* webpackChunkName: "starter" */ './views/utility/starter')
  },
  {
    path: '/organisation-structure',
    name: 'organisation-structure',
    meta: { authRequired: true, title: 'organisation-structure', roles: ['all'] },
    component: () => import(/* webpackChunkName: "organisation-structure" */ './views/organisation-structure/index')
  },
  {
    path: '/activity-log',
    name: 'activity-log',
    meta: { title: 'Activity Log', roles: ['admin', 'hrm']  },
    component: () => import('./views/activity-log/index'),
  },
  {
    path: '/pages/404',
    name: 'Error-404',
    meta: { authRequired: true, roles: ['all'] },
    component: () => import(/* webpackChunkName: "404" */ './views/utility/404')
  },
  {
    path: '/pages/500',
    name: 'Error-500',
    meta: { authRequired: true, roles: ['all'] },
    component: () => import(/* webpackChunkName: "500" */ './views/utility/500')
  }
]
